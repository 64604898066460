import React, { useState } from "react"
import Helmet from "react-helmet"

import Layout from '../components/layout'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { registerUser } from "../lib/API"
import swal from 'sweetalert2'
import { navigate } from "gatsby"



const MediaPage = () => {
    const siteTitle = "Blackheath and Charlton Baptist Church"

    const [ formData, setFormData ] = useState( {
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    } )
    

    const submitNewUser = async ( event ) => {
        event.preventDefault()
        try{
            const res = await registerUser( formData )
            if( res.error )
                throw res

            console.log( res )
            swal.fire( {
                title: 'Success!',
                text: 'Created new account successfully',
                icon: 'success'
              } ).then( () => navigate( "/my-account" ) )
            
        }catch( e ){
            console.log( e.message[0].messages[0] )
            swal.fire( {
                title: 'Error',
                text: e.message[0].messages[0].message,
                icon: 'error'
              } )
        }
                

    }


    return (
            
        <>
            <Helmet title={siteTitle} />
            {/* <Header icon={headerIcon} images={headerImages} headerTitle={headerTitle} tint={0.3} headerButtons={headerButtons} /> */}

            
          

            <section id="one" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Register</h2>
                        </header>
                    </div>

                    <div className="col-12">
                        <form className="login-form" method="POST" onSubmit={submitNewUser}>
                            <label>First Name</label>
                            <input className="form-input" required type="text" name="firstName" placeholder="Joe" onChange={( e ) => setFormData( { ...formData, firstName: e.target.value } )}/>

                            <label>Last Name</label>
                            <input className="form-input" required type="text" name="lastName" placeholder="Bloggs" onChange={( e ) => setFormData( { ...formData, lastName: e.target.value } )} />

                            <label>Email</label>
                            <input className="form-input" required type="email" name="email" placeholder="joebloggs@example.com" onChange={( e ) => setFormData( { ...formData, email: e.target.value } )}/>

                            <label>Password</label>
                            <input className="form-input" required type="password" name="password" onChange={( e ) => setFormData( { ...formData, password: e.target.value } )} />

                            <br />
                            <ul className="actions uniform">
                                <li><AniLink fade duration={0.4} to="/login" className="button">Go Back</AniLink></li>
                                <li><input type="submit" className="button special" value="Create Account" /></li>
                            </ul>
                            
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MediaPage